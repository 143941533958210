
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import SupplierStaffList from "@/components/admin/list/SupplierStaffList.vue";
import { ISupplier } from "@/types/supplier";

@Component({
  components: { SupplierStaffList }
})
export default class extends Vue {
  @Action("supplier/adminGet")
  public getSupplier!: (id: number) => void;

  @Getter("supplier/single")
  public supplier!: ISupplier;

  @Mutation("supplier/clear")
  public clear!: () => void;

  // fields
  public supplier_id = 0;
  public disp = false;

  public async created() {
    this.supplier_id = Number(this.$route.params.supplier_id);
    this.clear();
    await this.getSupplier(this.supplier_id);
    this.disp = true;
  }
}
